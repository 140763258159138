import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

class FormInput extends Component {

  getValidationState = () => {
    const { error, active, visited, submitFailed } = this.props.meta;
    const hasError = error && !active && (visited || submitFailed);
    if (hasError) {
      return 'error'
    }
    return null;
  }

  render() {
    const {
      input,
      label,
      name,
      children,
      loginError,
      meta: { touched, error, warning },
      ...inputProps
    } = this.props;

    return (
      <Form.Group
        controlId={name}
        validationstate={this.getValidationState()}
        className='component-formInput'
      >
        <Form.Control
          {...inputProps}
          onChange={input.onChange}
          onFocus={input.onFocus}
          onBlur={input.onBlur}
          value={input.value}
          className='field-required form-control'
        >
          {children}
        </Form.Control>
        {touched &&
          ((loginError && <span>{loginError.message}</span>) ||
            (error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </Form.Group>
    );
  }
}
export default FormInput;
