import { axiosAuth } from '../helper/axios-auth';

import {
  ANTIVIRUS_KEY,
  FETCHING_KEY,
  ANTIVIRUS_KEY_DENY
} from './types';

export function getAntivirusKey(identifier) {
  return async (dispatch) => {
    try {
      dispatch(fetchingKey());
      const axios = await axiosAuth();
      const response = await axios.get('/antivirus', {
        params: {
          NR_CPF: identifier,
          ID_CLIENTE_CORPORATIVO: 183,
          platform: 'web'
        }
      });
      dispatch(logIn(response.data.CONTRATO));
    } catch (error) {
      let errorMessage = '';

      if (error.message === 'Network Error') {
        errorMessage = {
          message: 'Verifique a sua conexão e tente novamente.',
          status: null,
        };
      }
      if (error.response && error.response.data) {
        errorMessage = {
          message: error.response.data.MENSAGEM_RETORNO,
          status: error.response.status,
        };
      }
      if (!errorMessage) {
        errorMessage = {
          message: 'Ocorreu um erro. Tente novamente mais tarde.',
          status: 500,
        };
      }
      dispatch(rejectLogIn(errorMessage))
    }
  }
}

export function logIn(data) {
  return {
    type: ANTIVIRUS_KEY,
    payload: data,
  }
}

function rejectLogIn(errorMessage) {
  return {
    type: ANTIVIRUS_KEY_DENY,
    payload: errorMessage,
  }
}

function fetchingKey() {
  return {
    type: FETCHING_KEY,
  }
}
