import React, { Component } from 'react';
import { Image, Row } from 'react-bootstrap';

class Footer extends Component {

  render() {
    return (
      <div id="footer">
        <Row className="middle no-gutters">
          <Image className="img-fluid label-logo" src={`/img/logos.png`}/>
        </Row>
        <Row className="bottom no-gutters">
          <Image className="img-fluid label-logo" src={`/img/product-logo.png`} alt="Responsive phone"/>
          <p>© 2020 - <span className="brand-fire">PROTEÇÃO ROUBO E FURTO</span> todos os direitos reservados</p>
        </Row>
      </div>
    )
  }
}

export default Footer;
