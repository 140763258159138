import {
  ANTIVIRUS_KEY,
  FETCHING_KEY,
  ANTIVIRUS_KEY_DENY
} from '../actions/types'

const INITIAL_STATE = {
  contracts: null,
  fetching: false,
  loginError: null,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ANTIVIRUS_KEY:
      return {
        ...state,
        contracts: action.payload,
        fetching: false,
      }
    case FETCHING_KEY:
      return {
        ...state,
        fetching: true,
        loginError: INITIAL_STATE.loginError,
      }
    case ANTIVIRUS_KEY_DENY:
      return {
        ...state,
        fetching: false,
        loginError: action.payload,
      }
    default:
      return state;
    }
}
