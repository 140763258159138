import React, { Component } from 'react';
import { compose } from 'redux';
import Home from './Home';
import AntivirusToken from './AntivirusToken';
import { Route, withRouter } from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <div>
        <Route exact path='/' component={Home} />
        <Route exact path='/antivirus' component={AntivirusToken} />
      </div>
    );
  }
}

export default compose(withRouter)(App);
