import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './helper/redux-store';
import './styles/index.scss';
import App from './containers';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  (
    <Provider store={configureStore()}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  ),
  document.getElementById('root')
);
