import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from '../components/Footer';
import { Redirect } from 'react-router-dom';
import { Image, Row, Button, Container, Col } from 'react-bootstrap';

class Antivirus extends Component {
  getSoftwareKey = () => {
    const keys = this.props.contracts.map(contrato => {
      return contrato.SOFTWARES.find(item =>
        item.DS_SOFTWARE === 'McAfee Multi Device (MMA)'
      );
    });
    return keys;
  }

  renderButtons = (softKeys) => {
    return softKeys.map((item, key) =>
      <Button key={item.ID_SOFTWARE_LICENCA} href={item.URL} target="_blank" className="download-button">
        <span>DISPOSITIVO {key + 1} </span> DOWNLOAD
      </Button>
    );
  }

  render () {
    const { fetching, contracts } = this.props;

    if (fetching) {
      return <div>loading...</div>
    } else if (!contracts) {
      return <Redirect to={'/'} />
    }

    let softKeys = this.getSoftwareKey();
    let buttons = this.renderButtons(softKeys);

    return (
      <div id="antivirus">
        <Image className="d-block d-sm-none img-fluid label-logo" src={`/img/product-logo.png`} alt="Responsive background mobile"/>
        <Image className="d-none d-sm-block img-fluid" src={`/img/background.png`} alt="Responsive background"/>

        <Container className="antivirus-access">
          <Row>
            <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 1 }} lg={{ span: 3, offset: 2 }}>
              <Image className="img-fluid" src={`/img/illustration-phone.png`} alt="Responsive phone"/>
            </Col>
            <Col xs={{ span: 12 }} md={{ span: 7 }} lg={{ span: 7 }}>
              <h2>Para fazer o download do seu Antivírus clique abaixo e siga os passos de instalação</h2>

              <Row className="download-row">
                {buttons && buttons.length > 1 ?
                  <Col xs={12}>
                    <span>Verificamos que você tem direito ao download de mais de uma licença do antivírus</span>
                  </Col>
                : null}
                <div className="buttons-row">
                  {buttons}
                </div>
              </Row>

              <p>Se tiver dúvidas de como instalar, <a target="_blank" href="https://www.cdf.net/mcafee/Instalacao_McAfee.pdf">acesse aqui o manual de instalação</a></p>
              <p>Para maiores informações, ligue para nossa <span>central de atendimento</span> nos telefones <span>4020 9816 ou 0800 025 8916</span></p>
              <p>Se preferir fale conosco via:</p>

              <Button href="https://cdf.cxcontact.com.br/WhatsAppbot/webchatnew/customwebchat.html?s=3-ylsRAVXLk.VFI9H_qUfeYPm4NMhnN8UFB8Zd2gczX_eTQtWg3W-aQ&username=anonimo&parceiroNegocio=protecao_roubo_e_furto" target="_blank" className="chat-button">CHAT</Button>
            </Col>
          </Row>
        </Container>

        <Footer />
      </div>
    );
  }
}

function mapStateToProps({ authReducer }) {
  const { contracts, fetching } = authReducer;

  return { contracts, fetching };
}

export default connect(mapStateToProps)(Antivirus);
