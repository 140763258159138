import React, { Component } from 'react';
import { Image, Row, Col, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button } from 'react-bootstrap';
import { getAntivirusKey, logIn } from '../actions';
import { Form, Field, reduxForm } from 'redux-form';
import FormInput from '../components/FormInput';
import normalization from '../helper/normalization';
import validate from '../helper/validation';
import Footer from '../components/Footer';

class Home extends Component {
  onSubmit = (values) => {
    const { identifier } = values;
    this.props.getAntivirusKey(identifier.replace(/\D/g, ""));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.contracts && nextProps.contracts) {
      this.props.history.push('/antivirus');
    }
  }

  render () {
    const {
      handleSubmit,
      fetchingUser,
      loginError
    } = this.props;

    return (
      <div id="home">
        <Image className="d-block d-sm-none img-fluid label-logo" src={`/img/product-logo.png`} alt="Responsive background mobile"/>
        <Image className="d-none d-sm-block img-fluid" src={`/img/background.png`} alt="Responsive background"/>

        <Container className="antivirus-form">
          <Row>
            <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 1 }} lg={{ span: 3, offset: 2 }} xl={{ span: 3, offset: 2 }}>
              <Image className="img-fluid" src={`/img/illustration-phone.png`} alt="Responsive phone"/>
            </Col>
            <Col xs={{ span: 12, offset: 0 }} md={{ span: 4, offset: 1 }} lg={{ span: 4, offset: 1 }} xl={{ span: 4, offset: 1 }}>

              <h2>Acesso Restrito</h2>

              <Form onSubmit={handleSubmit(this.onSubmit)}>
              <Field
                name='identifier'
                label='CPF'
                maxLength={14}
                placeholder='Informe aqui seu CPF'
                className='field-required form-control'
                component={FormInput}
                normalize={normalization.cpf}
                validate={[ validate.notEmpty, validate.cpfLength ]}
                loginError={loginError}
                inputMode='numeric'
              />
                <Button type='submit' className='access-button' disabled={fetchingUser}>
                  {
                    fetchingUser ?
                      'Solicitando...':
                      'ACESSAR'
                  }
                </Button>
              </Form>

            </Col>
          </Row>
        </Container>

        <Footer />
      </div>
    );
  }
}

function mapStateToProps({ authReducer }) {
  const { contracts, fetchingUser, loginError } = authReducer;

  return { contracts, fetchingUser, loginError };
}

export default compose(
  connect(mapStateToProps, { getAntivirusKey, logIn }),
  reduxForm({
    form: 'login',
  })
)(Home);
